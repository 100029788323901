import { algebraPoolABI } from '../../../abis/v3';
import { useAsync } from 'react-use';
import { getContract } from '@thirdweb-dev/react';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export function useAlgebraPoolTickSpacing({
  address
}: {
  address: string | undefined;
}) {
  const chain = useSupportedChain();

  const { value, loading, error } = useAsync(async () => {
    if (!chain || !address) {
      return;
    }

    const contract = await getContract({
      address,
      contractTypeOrAbi: algebraPoolABI,
      network: chain
    });

    // @ts-ignore
    return contract.call('tickSpacing');
  }, [chain, address]);

  return {
    data: value,
    isLoading: loading,
    isError: error
  };
}
