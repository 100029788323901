import { useMemo } from 'react';
import { ADDRESS_ZERO, ExtendedNative, Token } from '@cryptoalgebra/scribe-sdk';
import {
  DEFAULT_NATIVE_NAME,
  DEFAULT_NATIVE_SYMBOL
} from 'src/constants/common';
import { Address, NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useBalance } from '@thirdweb-dev/react';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export function useAlgebraToken(address: Address | undefined) {
  const isETH = address === ADDRESS_ZERO || address === NATIVE_TOKEN_ADDRESS;
  const chainId = useSupportedChain()?.chainId;

  const { data: tokenData, isLoading } = useBalance(
    isETH ? undefined : address
  );

  return useMemo(() => {
    if (!address || !chainId) return;

    if (address === ADDRESS_ZERO || address === NATIVE_TOKEN_ADDRESS)
      return ExtendedNative.onChain(
        chainId,
        DEFAULT_NATIVE_SYMBOL,
        DEFAULT_NATIVE_NAME
      );

    if (isLoading || !tokenData) return undefined;

    const { symbol, name, decimals } = tokenData;

    return new Token(chainId, address, +decimals, symbol, name);
  }, [address, chainId, isLoading, tokenData]);
}
